.users-list {
  margin: 20px 50px;
}

.users-header {
  display: grid;
  grid-template-columns: auto 140px;
  margin: 5px 50px;
  align-items: center;
}

// Not used?
.user-search-bar {
  width: 500px;
  color: #5e5e5e;
  background-color: #f1f3f4;
  border-radius: 10px;
  padding: 10px;
  outline: none;
  border: none;
  font-size: 16px;
}

.user-searchbar {
  min-width: 220px;
}

.sidebar-cancel-button {
  position: absolute;
  right: 0px;
}

.sidebar-dropdown-menu {
  position: absolute;
  right: 40px;
}

.users-filter {
  display: grid;
  grid-template-columns:
    auto minmax(180px, 220px) minmax(180px, 220px) minmax(180px, 220px)
    minmax(180px, 220px);
  align-items: center;
}

.users-filter-item {
  margin: 0px 15px;
  z-index: 12;
}

.csv-download {
  display: flex;
  justify-content: right;
  margin-right: 120px;
  margin-top: 10px;
  margin-bottom: 10px;

  a {
    color: var(--rp-blue);
    &:hover {
      color: var(--rp-blue-hover);
    }
  }
}

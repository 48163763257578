.update-entry-form {
  display: grid;
  grid-template-columns: 150px 200px;
  grid-template-rows: repeat(3, 65px) repeat(10, 50px);
  align-items: center;
  margin: 0px 50px;
}

.hard-lock-button {
  padding: 10px;
  background: #4285f4;
  color: white;
  border-radius: 5px;
  text-align: center;
  cursor: pointer;

  &:hover {
    background: #5492f6;
  }

  &:focus {
    background: #4285f4;
  }
}

.update-date-picker {
  position: absolute;
  left: -65%;
  margin-top: 150px;
}

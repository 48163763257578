.timesheets-page {
  position: relative;
  margin: 60px 30px;
}

.timesheets-header {
  width: 800px;
  display: grid;
  grid-template-columns: 150px 50px 50px 400px 150px;
  align-items: center;
  padding: 30px;
}

.timesheet-wrapper {
  width: 800px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);

  @media (max-width: 850px) {
    left: inherit;
    transform: inherit;
  }
}

.timesheet-filters {
  width: 800px;
  display: grid;
  grid-template-columns: auto 200px 200px;
  grid-gap: 30px;
  margin-bottom: 30px;
  z-index: 11;
  position: relative;
}

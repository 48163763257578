.delivery-page {
  position: relative;
  width: 100%;
  max-width: 1500px;
  min-width: 800px;
  left: 50%;
  transform: translateX(-50%);

  @media screen and (max-width: 800px) {
    left: 0%;
    transform: translateX(0%);
  }
}

.delivery-filter-options {
  display: grid;
  grid-template-columns: 200px 200px 200px 200px 200px 200px auto 150px;
  column-gap: 50px;
  align-items: center;
  margin-bottom: 30px;
}

#deliveries {
  position: relative;
  border-collapse: collapse;
  width: 100%;
  table-layout: auto;
}

#deliveries td {
  border: 1px solid #ddd;
  padding: 4px;
  font-size: 14px;
}

#deliveries tr:nth-child(even) {
  background-color: rgb(250, 251, 252);
}

#deliveries tr:hover {
  background-color: rgb(245, 246, 247);
}

#deliveries th {
  border-bottom-width: 2px;
  padding-top: 12px;
  padding-bottom: 8px;
  padding-left: 8px;
  text-align: left;
  //background-color: #4285f4;
  color: rgb(94, 108, 132);
  font-size: 14px;
  border-bottom: 2px solid #ddd;
  padding: 8px;
  font-weight: 500;
}

.rbc-row-content {
  z-index: 0 !important;
}

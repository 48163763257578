.logo-effect {
  width: 180px;
  height: 180px;
  animation-name: example;
  animation-duration: 6s;
  animation-iteration-count: infinite;
  transform-origin: top right;
}

/* The animation code */
@keyframes example {
  0% {
    transform: translate(0px, 5px) rotate(-5deg) rotate3d(1, 0.5, 0.2, 0deg);
  }
  25% {
    transform: translate(-5px, 0px) rotate(0deg) rotate3d(0.7, 0.6, 0.2, 10deg);
  }
  50% {
    transform: translate(0px, -5px) rotate(-3deg) rotate3d(1, 0.5, 0.2, 0deg);
  }
  75% {
    transform: translate(5px, 0px) rotate(3deg) rotate3d(0, 0.5, 0.2, -10deg);
  }
  100% {
    transform: translate(0px, 5px) rotate(-5deg) rotate3d(1, 0.5, 0.2, 0deg);
  }
}

.calendar-group {
  position: relative;
  width: 100%;
  display: flex;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #d9d9d9;
  height: var(--row-height);
  line-height: 40px;
}

.calendar-group-left-side {
  display: grid;
  width: 15%;
  align-items: center;
  grid-template-columns: auto 60px 40px;
  white-space: nowrap;
}

.calendar-group-hours {
  font-size: 14px;
  text-align: right;
  right: 0;
}

.calendar-group-text {
  position: relative;
  font-size: 14px;
  width: 80%;
  left: 20px;
  text-overflow: ellipsis;
  overflow: hidden;
  line-height: normal;

  &:hover {
    display: flex;
    padding-right: 10px;
    overflow: visible;
    align-items: center;
    z-index: 4;
    height: 40px;
  }

  &:hover ~ .dropdown-arrow-container {
    position: relative;
    z-index: 2;
    background: white;
    padding-top: 8px;
    padding-bottom: 8px;
    box-shadow: 2px 0 1px rgba(0, 0, 0, 0.1);
  }

  &:hover ~ .calendar-group-hours {
    background: white;
    z-index: 3;
  }
}

.calendar-group-dropdown-button {
  position: relative;
  width: 30px;
  height: 30px;
  right: 20px;
  margin: 0;
  padding: 0;
  pointer-events: auto;
}

.calendar-group-dropdown {
  max-height: 0;
  overflow: hidden;
}

.header {
  display: flex;
  position: fixed;
  top: 0;
  width: 100vw;
  height: var(--rp-header-height);
  background-color: #333333;
  align-items: center;
  justify-content: center;
  user-select: none;
  z-index: 100;
}

.header-logo {
  position: absolute;
  left: 0;
  margin-left: 15px;
  justify-self: flex-start;
}

.notification-icon-wrapper {
  position: relative;
  margin: 0 2rem 0 1rem;

  &:hover {
    cursor: pointer;
  }
}

.notification-icon {
  color: #6b9df5;
}

.header-sign-out {
  color: var(--rp-grey-100);
  text-decoration: none;
  transition: color 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
  padding: 15px;

  &:hover {
    cursor: pointer;
    color: white;
  }
}

.header-pending-number {
  z-index: 2;
  font-size: 12px;
  position: relative;
  color: white;
  font-weight: bold;
  padding: 5px;
}

.header-pending-number-oval {
  display: flex;
  z-index: 1;
  position: absolute;
  left: 18px;
  bottom: 13px;
  min-width: 16px;
  height: 16px;
  margin: 10px 0 0 -10px;
  background: red;
  border-radius: 50%;
  align-items: center;
  align-content: center;
  justify-content: center;
}

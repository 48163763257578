.calendar-month-top-right {
  width: 100%;
  display: flex;
}

.calendar-month {
  align-items: end;
  text-align: center;
  position: relative;
  height: 65px;
  bottom: 0;
  display: grid;
  grid-column: 1;
  grid-template-columns: repeat(31, 1fr);
}

.calendar-month-selection-section {
  width: 85%;
}

.calendar-month-selection-wrapper {
  position: relative;
  width: auto;
  border-bottom: 1px solid #d9d9d9;
  border-top: none;
  border-left: none;
  border-right: none;
  background: white;
  z-index: 1;
  display: grid;
  align-items: center;
  height: calc(100% - 65px);
  padding: 0 2rem;
}

.calendar-month-selection {
  width: 100%;
  height: 3.75rem; // 60px
  padding: 0.625rem; // 10px
  margin-left: auto;
  display: flex;
  align-items: center;
}

.calendar-header-sorting {
  display: grid;
  grid-template-columns: 110px repeat(2, 120px);
  align-items: center;
}

.calendar-header-sorting-button {
  height: 40px;
  background-color: white;
  border: none;
  color: #6f6f70;
  border-radius: 5px;
  width: 120px;
  display: flex;
  justify-content: center;

  &:hover {
    background-color: #f0f0f0;
  }

  &.selected {
    color: #4285f4;
  }
}

.calendar-month-selection-today {
  left: 30px;
  width: 88px;
  height: 40px;
  border: 1px solid #d9d9d9;
  border-radius: 5px;
  background: white;
  font-size: 14px;
  margin-right: 1rem;

  text-align: center;
  outline: none;
  transition: background 0.35s;

  cursor: pointer;

  &:hover {
    background: #e9e9e9;
  }

  &:active {
    background: #d9d9d9;
    border: none;
  }
}

.calendar-button {
  width: 45px;
  height: 45px;
  margin: auto;
  padding: 0;
  border: none;
  background: #e9e9e900;
  outline: none;
  border-radius: 50%;
  transition: background 0.5s;
  cursor: pointer;

  &:hover {
    background: #e9e9e9;
  }
}

.calendar-month-text {
  padding-left: 1.25rem; // 20px
  padding-right: 3.125rem; // 50px
  font-size: 22px;
  color: var(--rp-grey-800);
  flex-grow: 3;
}

.previous-icon {
  transform: rotate(180deg) scale(0.7);
}

.next-icon {
  transform: scale(0.7);
}

.settings-page {
  position: relative;
  top: 60px;
  display: grid;
  grid-template-columns: 320px auto;
}

.settings-body {
  position: relative;
}

.overlay-box-backdrop {
    position: fixed;
    left: 0px;
    top: 0px;
    height: 100vw;
    width: 100vw;
    background: rgba(0, 0, 0, 0.05);
    backdrop-filter: blur(5px);
    z-index: 15;
  }
  
  .clickable-backdrop {
    position: fixed;
    height: 100vw;
    width: 100vw;
    user-select: auto;
  }

  .overlay-box-cancel-button {
    position: absolute;
    top: 0px;
    right: 0px;
  }
  
  .overlay-box-left-border {
    position: absolute;
    border-style: none none none solid;
    border-width: 5px;
    border-color: #4285f4;
    height: 100%;
    left: 0;
    top: 0;
  }
  
  .overlay-box {
    display: block;
    position: fixed;
    top: 100px;
    left: 50%;
    transform: translate(-50%, 0);
    padding: 50px;
    background: white;
    width: 350px;
   // height: 400px;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.4);
    z-index: 6;
    pointer-events: auto;
  }
  
  .overlay-box-heading {
    position: relative;
    top: -15px;
    margin: 10px 0;
    padding: 0px;
  }
  
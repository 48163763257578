.bank-holiday-input {
  display: grid;
  grid-template-columns: auto auto 60px;
  grid-template-rows: 90px;
  align-items: center;
}

.bank-holiday-input-field {
  width: 160px;
}

.bank-holiday-input-text {
  font-size: 14px;
  padding: 5px;
}

.bank-holiday-click-outside {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vw;
  opacity: 0;
  z-index: var(--rp-z-index-popover);
}

.bank-holiday-date-input-wrapper {
  position: fixed;
  z-index: var(--rp-z-index-popover);
  box-shadow: var(--rp-box-shadow-sm);
}

.calendar-filter {
  background-color: white;
  width: 15%;
  border-right: 2px solid var(--rp-grey-400);
  margin-right: -1px;
}

.team-filter-wrapper {
  position: relative;
  height: 50px;
  margin-top: 10px;
}

.calendar-filter-wrapper {
  margin-right: 2.5rem; // 40px
  height: 3.125rem; // 50px
  flex-grow: 3;
  max-width: 15rem;
}

.calendar-filter-options {
  margin: 20px;
}
.dropdown-button {
  position: relative;
  display: flex;
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  &:hover {
    background: rgb(235, 235, 235);
  }

  transition: background 0.2s;
}

.dropdown-background {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.dropdown-dot {
  border-radius: 50%;
  background-color: #6f6f70;
  width: 4px;
  height: 4px;
  margin: 2px;
}

.dropdown {
  position: absolute;
  top: 45px;
  right: 0;
  min-width: 200px;
  background: white;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  border: solid 1px rgba(200, 200, 200, 0.7);
  z-index: 5;
  overflow: hidden;
  opacity: 0;
  min-height: 10px;
  padding: 10px 0px;

  transition: opacity 0.2s;
}

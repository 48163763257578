.header-link {
  color: white;

  a {
    color: var(--rp-grey-100);
    text-decoration: none;
    transition: color 0.3s cubic-bezier(0.25, 0.1, 0.25, 1);
    margin: 35px;
    padding: 15px;
    border-radius: var(--rp-rounded-sm);

    &:hover {
      color: white;
    }
  }
}

.header-link-active {
  a {
    background-color: var(--rp-grey-700);
  }
}

.calendar-row {
  position: relative;
  width: 100%;
  display: flex;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 1px solid #d9d9d9;
  height: var(--row-height);
}

.calendar-row-left-side {
  display: grid;
  width: 15%;
  align-items: center;
  grid-template-columns: 55px auto 40px 40px;
  white-space: nowrap;
  background: #f1f1f1;
}

.calendar-row-icon {
  position: relative;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #c5c5c5;
  margin: auto 10px;
  text-align: center;
}

.calendar-row-icon-text {
  position: relative;
  color: #ffffff;
  font-weight: normal;
  font-size: 15px;
  top: 50%;
  transform: translate(0, -50%);
}

.calendar-row-text {
  position: relative;
  text-overflow: ellipsis;
  font-size: 14px;
  overflow: hidden;
  &:hover {
    display: flex;
    overflow: visible;
    align-items: center;
    z-index: 2;
    background: #f1f1f1;
    width: 105%;
    height: 40px;
  }

  &:hover ~ .dropdown-arrow-container {
    position: relative;
    background: #f1f1f1;
    z-index: 2;
    padding: 8px;
    box-shadow: 2px 0px 1px rgba(0, 0, 0, 0.1);
  }

  &:hover ~ .user-availability-wrapper {
    background: #f1f1f1;
    z-index: 3;
  }
}

.calendar-row-dropdown-button {
  position: relative;
  width: 30px;
  height: 30px;
  margin: auto;
  pointer-events: auto;
}
.calendar-row-dropdown {
  max-height: 0;
  transition: max-height 0.1s ease-out;
  overflow: hidden;
}

.user-availability-wrapper {
  font-size: 14px;
  position: relative;
  height: 100%;
}

.user-availability {
  position: absolute;
  top: 50%;
  right: 0px;
  transform: translateY(-50%);
}

.calendar-entry-left-side {
  display: flex;
  width: 15%;
  align-items: center;
  white-space: nowrap;
  background: #ececec;
}

.calendar-entry-wrapper {
  display: flex;
  font-size: 14px;
  padding-left: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  &:hover {
    overflow: visible;
    display: flex;
    align-items: center;
    height: 40px;
    background: #ececec;
    padding-right: 8px;
    z-index: 2;
  }
}

.calendar-entry-text {
  position: relative;
  text-overflow: ellipsis;
  overflow: hidden;
  z-index: 2;
  color: inherit;
  text-decoration: none;
  &:hover {
    overflow: visible;
    display: flex;
    align-items: center;
    height: 40px;
    background: #ececec;
    z-index: 2;
  }
}

@media (max-width: 820px) {
  .user-availability {
    font-size: 12px;
  }

  .calendar-row-left-side {
    grid-template-columns: 55px 0 auto auto;
  }
}

@media (max-width: 680px) {
  .user-availability {
    display: none;
  }

  .calendar-row-left-side {
    grid-template-columns: 55px 0 0 auto;
  }
}

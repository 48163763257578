.horizontal-divider {
  margin: 10px 0;
  position: relative;
  height: 2px;
  background: var(--rp-grey);
}

.bank-holidays-header {
  display: grid;
  grid-template-columns: auto 40px;
  align-items: center;
  grid-template-rows: 40px;
}

.bank-holidays {
  position: relative;
  margin: 0px 40px;
}

.bank-holidays-heading {
  margin-block-end: 0;
  margin-block-start: 0;
}

.update-location-form {
  display: grid;
  grid-template-columns: 150px 200px;
  grid-template-rows: 50px;
  align-items: center;
  margin: 0px 50px;
}

.bank-holiday-list {
  position: relative;
  height: 50vh;
  overflow-y: auto;
}

.update-location-button {
  position: absolute;
  margin: 50px 50px;
  right: 0;
}

.calendar-content {
  padding-top: 206px; // FIXME: The layout shouldn't rely on this exact pixel amount as `padding-top`
  width:100%;
  position: relative;
  z-index: 0;
}

.left-panel-container {
  position: absolute;
  width: 100%;
  height: auto;
}

.left-panel {
  position: fixed;
  height: 100%;
  width: 15%;
  border: none;
  border-right: 1px solid #e7e7e7;
  z-index: 1;
  pointer-events: none;
}

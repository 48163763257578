.update-rate-card {
  overflow-y: auto;
}

.update-rate-card-form {
  display: grid;
  grid-template-columns: 150px 200px;
  grid-template-rows: 50px;
  align-items: center;
  margin: 0px 50px;
}

.update-rate-card-rate {
  display: grid;
  grid-template-columns: 150px 200px 20px;
  grid-template-rows: 50px;
  align-items: center;
  margin: 0px 50px;
}

.project-board {
  .columns {
    display: flex;
    justify-content: space-around;
  }

  .column {
    flex: 1;
    border: 1px solid #ccc;
    padding-left: 1rem;
    margin: 1rem;
  }

  .card {
    border: 1px solid #ccc;
    padding: 1rem;
    margin: 1rem;
    background-color: #f9f9f9;
  }
}

.card-link {
  color: black;
  text-decoration: none;
}

.calendar-backdrop {
  position: fixed;
  top: 0;
  bottom: 0px;
  left: 15%;
  display: grid;
  align-items: start;
  grid-column: 1;
  width: 85%;
  height: 100%;
  user-select: none;
  grid-template-columns: repeat(31, 1fr);
  z-index: -5;
}

.calendar-day-backdrop {
  height: 100%;
  position: relative;
  display: grid;
  font-size: 16px;
  width: auto;
  background: #f6f6f6 100%;
  margin-left: -1px;
  border-top: none;
  border-left: 1px solid #d9d9d9;
  border-right: 1px solid #d9d9d9;
  border-bottom: none;
}

:root {
  --row-height: 40px;
  --rp-header-height: 3.75rem; // 60px

  --rp-blue: #4285f4;
  --rp-blue-hover: #6b9df5;
  --rp-light-grey: #fafafa;
  --rp-grey: #d9d9d9;
  --rp-darkgrey: #6f6f70;
  --rp-yellow: #ffdd55;
  --rp-green: #31c166;
  --rp-grey-100: #e4e4e4;
  --rp-grey-200: #b4b4b4;
  --rp-grey-400: #d9d9d9;
  --rp-grey-600: #525252;
  --rp-grey-700: #414141;
  --rp-grey-800: #333333;

  --rp-red-400: #e02c2c;

  --rp-rounded-sm: 4px;

  --rp-border-light: 1px solid var(--rp-grey-100);
  --rp-border-medium: 1px solid var(--rp-grey-400);

  --rp-z-index-default: 0;
  --rp-z-index-modal: 1;
  --rp-z-index-popover: 99;

  --rp-box-shadow-sm: 0 6px 5px 0 rgba(0 0 0 / 0.05)
}

button {
  font-family: "Roboto", sans-serif;
  color: #6f6f70;
  font-weight: bold;
  padding: 0;
  align-items: center;
}

body {
  margin: 0;
  top: 0;
  color: var(--rp-grey-600);
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.move-in-right {
  animation-name: move-in-right;
  animation-duration: 0.1s;
}

.move-in-left {
  animation-name: move-in-left;
  animation-duration: 0.1s;
}

input {
  color: hsl(0, 0%, 20%);
  position: relative;
  box-sizing: border-box;
  background-color: white;
  border-color: rgb(204, 204, 204);
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  min-height: 32px;
  padding-left: 10px;
  font-size: 16px;
  font-family: "Roboto", sans-serif;

  &:disabled {
    border-color: hsl(0, 0%, 90%);
    background-color: hsl(0, 0%, 95%);
    color: hsl(0, 0%, 60%);
  }
}

input:focus {
  outline-color: #2684ff;
}

.submit-button {
  position: relative;
  right: 0;
  width: 200px;
  padding: 10px;
  background: var(--rp-blue);
  color: white;
  border: none;
  font-size: 16px;
  border-radius: 5px;
  outline: none;

  cursor: pointer;

  &:hover {
    background: #5492f6;
  }

  &:focus {
    background: #4285f4;
  }
}

#inner-scrollbar::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #f5f5f5;
}

/* width */
#inner-scrollbar::-webkit-scrollbar {
  width: 10px;
}

/* Handle */
#inner-scrollbar::-webkit-scrollbar-thumb {
  background: rgb(172, 172, 172);
  border-radius: 10px;
}

/* Handle on hover */
#inner-scrollbar::-webkit-scrollbar-thumb:hover {
  background: rgb(138, 138, 138);
}

@keyframes move-in-right {
  from {
    transform: translateX(50px);
  }
  to {
    transform: translateX(0px);
  }
}

@keyframes move-in-left {
  from {
    transform: translateX(-50px);
  }
  to {
    transform: translateX(0px);
  }
}

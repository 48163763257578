.chart-department {
  display: flex;
}

.chart-column {
  border-right: solid 1px rgba(0, 0, 0, 0.25);
}

.chart-department-heading {
  display: flex;
  align-content: center;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  width: 120px;
  height: 50px;
  font-weight: 600;
  font-size: 13px;
  background-color: rgba(0, 0, 0, 0.1);
  margin-bottom: 10px;
}

.chart-user {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  text-align: center;
  font-size: 12px;
  width: 100px;
  height: 50px;
  background-color: rgb(221, 221, 221);
  color: white;
  border-radius: 10px;
  margin: 5px auto;
  text-overflow: hidden;
  box-shadow: 2px 2px 2px rgba(80, 80, 80, 0.4);
}

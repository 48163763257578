.dropdown-arrow-container {
  pointer-events: auto;
  cursor: pointer;
  position: relative;
}

.dropdown-arrow {
  position: absolute;
  right: 0.25rem;
  top: 0;
  transform: translate(-50%, -50%) rotate(90deg) scale(0.6);
  transition: transform 0.1s;
}

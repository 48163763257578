.projects-page {
  position: relative;
  top: 60px;
}

.projects-header {
  display: grid;
  grid-template-columns: 200px 20px 200px 20px 200px 20px 200px;
  margin: 5px 50px;
  align-items: center;
}

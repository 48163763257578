.cancel-button {
    position: relative;
    padding: 15px;
    fill: #6f6f70;
  
    cursor: pointer;
  
    transition: background 0.2s;
  
    &:hover {
      fill: white;
      background: red;
    }
  }
.add-entry-backdrop {
  display: none;
  position: fixed;
  left: 0px;
  top: 0px;
  height: 100vw;
  width: 100vw;
  background: rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(5px);
  z-index: 0;
}

.clickable-backdrop {
  position: fixed;
  height: 100vw;
  width: 100vw;
  user-select: auto;
}

.add-entry-left-border {
  position: absolute;
  border-style: none none none solid;
  border-width: 5px;
  border-color: #4285f4;
  height: 100%;
  left: 0;
  top: 0;
}

.add-entry {
  display: block;
  position: fixed;
  top: 350px;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 50px;
  background: white;
  width: 350px;
  // height: 400px;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.4);
  z-index: 6;
  pointer-events: auto;
}

.add-entry-form {
  position: relative;
  display: grid;
  justify-content: space-between;
  grid-template-columns: 100px 200px;
  grid-template-rows: repeat(7, 55px);
  align-items: center;
}

.add-entry-submit-button {
  position: relative;
  right: 0px;
  width: 200px;
  padding: 10px;
  background: #4285f4;
  color: white;
  border: none;
  font-size: 16px;
  border-radius: 5px;
  outline: none;

  cursor: pointer;

  &:hover {
    background: #5492f6;
  }

  &:focus {
    background: #4285f4;
  }
}

.add-entry-button {
  position: relative;
  padding: 10px;
  width: 125px;
  height: 40px;
  background: #4285f4;
  border: none;
  border-radius: 5px;
  outline: none;
  color: white;
  font-size: 16px;
  font-weight: normal;

  cursor: pointer;

  &:hover {
    background: #5492f6;
  }

  &:focus {
    background: #4285f4;
  }
}

.add-entry-cancel-button {
  position: absolute;
  top: 0px;
  right: 0px;
  padding: 20px;
  fill: #6f6f70;

  cursor: pointer;

  transition: background 0.2s;

  &:hover {
    fill: white;
    background: red;
  }
}

.add-entry-heading {
  position: relative;
  top: -15px;
  margin: 10px 0;
  padding: 0px;
}

.add-entry-date-picker {
  margin-left: 360px;
}

.sidebar-link {
  height: 50px;
  border-bottom: 1px solid var(--rp-grey);

  &.active {
    border-right: 5px solid var(--rp-blue);
  }

  &:hover {
    background-color: var(--rp-blue);
    color: white;
    a {
      color: white;
    }
  }

  a {
    display: block;
    color: black;
    text-decoration: none;

    padding: 15px;
  }
}

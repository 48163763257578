.calendar-summary-row {
  position: absolute;
  display: grid;
  grid-template-columns: repeat(31, 1fr);
  left: 15%;
  width: 85%;
  overflow: hidden;
  height: var(--row-height);
  opacity: 1;
  bottom: 0px;
}

.summary-element-wrapper {
  position: relative;
}

.summary-element {
  position: absolute;
  width: 100%;
  height: 100%;
  bottom: 0px;
  opacity: 0.5;
}

.summary-element-unavailable {
  position: absolute;
  background-color: #3d3d3d;
  width: 100%;
  height: 100%;
  opacity: 0.1;
}

.project-roadmap {
  display: flex;
  margin-bottom: 50px;
}
.project-roadmap-week {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--rp-blue);
  border: solid 1px black;
  color: white;
  margin: -0.5px;
  font-size: 14px;
  z-index: -1;
}

.project-roadmap-week-header {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--rp-grey);
  border: solid 1px black;
  color: black;
  margin: -0.5px;
  font-size: 14px;
  z-index: -1;
}

.project-roadmap-week-body {
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid 1px black;
  margin: -0.5px;
  font-size: 14px;
  z-index: -2;
}

.roadmap-user-grid {
  display: grid;
  margin-top: 20px;
  grid-template-columns: 300px;
  grid-auto-rows: 25px;
}

.roadmap-user {
  display: flex;
  justify-content: left;
  align-items: center;
  background-color: var(--rp-grey);
  border: solid 1px black;
  color: black;
  margin: -0.5px;
  font-size: 14px;
  overflow: hidden;
  padding-left: 5px;
  text-overflow: ellipsis;
  white-space: nowrap;
  z-index: -1;
}

.roadmap-milestone-wrapper {
  position: relative;
  margin-bottom: -20px;
  margin-top: -10px;
  padding: 10px;
  right: 10px;
 
  cursor: pointer;
  z-index: 1;
  &:hover {
    z-index: 2;
  }
  &:hover > .project-roadmap-milestone {
    opacity: 1;
  }
  &:hover > .project-milestone-hover {
    display: block;
  }
}
.project-roadmap-milestone {
  width: 2px;
  height: 100%;
  background-color: rgb(255, 85, 73);
  opacity: 0.6;
  box-shadow: 1px 0px 1px rgba(0, 0, 0, 0.25);
}

.project-milestone-hover {
  position: absolute;
  display: none;
  color: black;
  left: 15px;
  top: 5px;
  overflow: visible;
  background-color: rgb(255, 231, 224);
  border: solid 1px rgb(255, 85, 73);
  padding: 6px;
  font-size: 14px;
  font-weight: 500;
  border-radius: 5px;
  pointer-events: none;
  white-space: nowrap;
  box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
  z-index: 2;
}

.project-roadmap-grid {
  display: grid;
  margin: 20px;
  margin-left: 0px;
  grid-auto-columns: 5px;
  grid-auto-rows: 25px;
}

.project-roadmap-text-bold {
  font-weight: 700;
}

.project-roadmap-left-side {
  display: grid;
  grid-template-columns: 250px 50px;
  grid-template-rows: 25px;
}

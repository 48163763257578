.create-user-backdrop {
  display: none;
  position: fixed;
  left: 0px;
  top: 0px;
  height: 100vw;
  width: 100vw;
  background: rgba(0, 0, 0, 0.05);
  backdrop-filter: blur(5px);
  z-index: 1;
}

.left-border {
  position: absolute;
  border-style: none none none solid;
  border-width: 5px;
  border-color: #4285f4;
  height: 100%;
  left: 0;
  top: 0;
}

.create-user {
  display: none;
  position: fixed;
  top: 300px;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 50px;
  background: white;
  width: 350px;
  height: 270px;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.4);
  z-index: 2;
  pointer-events: auto;
}

.create-user-form {
  display: grid;
  grid-template-columns: 150px 200px;
  grid-template-rows: repeat(3, 50px) 70px auto repeat(3, 70px);
  align-items: center;
}

.add-user-button {
  position: relative;
  padding: 10px;
  width: 125px;
  height: 40px;
  background: #4285f4;
  border: none;
  border-radius: 5px;
  outline: none;
  color: white;
  font-size: 16px;
  font-weight: normal;
  
  cursor: pointer;

  &:hover {
    background: #5492f6;
  }

  &:focus {
    background: #4285f4;
  }
}

.sign-in {
  position: absolute;
  margin: auto;
  background: linear-gradient(
    0deg,
    rgba(27, 27, 27, 1) 0%,
    rgba(52, 52, 52, 1) 100%
  );
  color: #e4e4e4;
  width: 100%;
  height: 100%;
  z-index: 100;

  h1 {
    font-weight: lighter;
    margin: 30px 0px 10px 0px;
  }
}

.sign-in-container {
  position: absolute;
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.sign-in-logo {
  position: relative;
  right: 0;
}

.microsoft-sign-in-wrapper {
  position: relative;
  top: 20px;
}

.roadmap-header {
  display: grid;
  grid-template-columns: auto 50%;
  align-items: center;
  margin-left: 40px;
  margin-right: 40px;
}

.roadmap-grid {
  display: grid;
  margin-left: 40px;
  margin-right: 40px;
  justify-content: center;
}

.roadmap-backdrop {
  position: relative;
  margin-top: 10px;
  margin-bottom: 100px;

  border-top: solid 1px rgba(0, 0, 0, 0.5);
  border-bottom: solid 1px rgba(0, 0, 0, 0.5);
}

.roadmap-button {
  width: 100px;
}

.roadmap-backdrop-left {
  position: absolute;
  left: 0;
  top: 0;
  width: 42px;
  height: 100%;
  background-color: var(--rp-grey);
  box-shadow: 5px 0px 5px -3px rgba(0, 0, 0, 0.5);
  z-index: 1;
}

.roadmap-backdrop-right {
  position: absolute;
  right: 0;
  background-color: var(--rp-grey);
  box-shadow: -5px 0px 5px -3px rgba(0, 0, 0, 0.5);
  top: 0;
  width: 42px;
  height: 100%;
  z-index: 1;
}

.roadmap-month {
  display: grid;
  grid-row-start: 1;
  grid-row-end: -1;
  margin-right: -0.5px;
  margin-left: -0.5px;
  border-right: solid 1px rgba(0, 0, 0, 0.25);
  border-left: solid 1px rgba(0, 0, 0, 0.25);
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
}

.roadmap-month-text {
  margin-top: 3px;
  color: black;
  font-size: 14px;
  font-weight: 400;
  height: 21px;
  border-bottom: solid 1px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.25);
  z-index: 5;
}

.roadmap-today {
  background-color: rgb(255, 166, 0);
  width: 3px;
  margin: -10px auto -20px;
  box-shadow: 1px 0 1px rgba(0, 0, 0, 0.25);
  z-index: 6;
  pointer-events: none;
}

.roadmap-year-selection-item-wrapper {
  margin-left: 30px;
}

.roadmap-month-selection {
  margin-left: auto;
  padding: 0.625rem; // 10px
  height: 3.75rem; // 60px
  display: grid;
  grid-template-columns:
    minmax(100px, 140px) minmax(30px, 60px) minmax(30px, 60px)
    minmax(180px, auto);
  grid-template-rows: auto;
  align-items: center;
}

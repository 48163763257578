.custom-button-plus {
  position: relative;
  width: 40px;
  height: 40px;

  transition: background 0.2s;

  cursor: pointer;

  &:hover {
    background: rgb(235, 235, 235);
  }
}

.plus-wrapper {
  position: relative;
  width: 16px;
  height: 16px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.plus-horizontal {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 100%;
  height: 2px;
  background: var(--rp-darkgrey);
  border-radius: 2px;
}

.plus-vertical {
  position: absolute;
  left:50%;
  transform: translateX(-50%);
  height: 100%;
  width: 2px;
  background: var(--rp-darkgrey);
  border-radius: 2px;
}

.calendar-day {
  position: relative; // needed to position the calendar separators
  display: grid;
  align-items: end;
  font-size: 14px;
  height: 4rem; // 64px
  background: transparent linear-gradient(180deg, #ffffff 0%, #f6f6f6 70%, #f6f6f6 100%);
  border: none;
}

.calendar-day-number {
  font-size: 18px;
  font-weight: bold;
  padding: 5px;
}

.calendar-day-border {
  position: absolute;
  border-top: none;
  border-left: none;
  border-right: 1px solid #d9d9d9;
  border-bottom: none;
  bottom: 0;
  height: 20px;
  background: linear-gradient(90deg, #ffffff 0%, #f6f6f6 70%, #f6f6f6 100%);
  transform: translateX(-1px);
}

@media (max-width: 1600px) {
  .calendar-day {
    font-size: 12px;
  }

  .calendar-day-number {
    font-size: 16px;
  }
}

@media (max-width: 1400px) {
  .calendar-day-text {
    display: none;
  }
  .calendar-day-number {
    font-size: 14px;
  }
}

@media (max-width: 1000px) {
  .calendar-day-number {
    display: none;
  }
}

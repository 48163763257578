.update-user-heading {
  margin: 30px 40px;
}

.update-user-form {
  display: grid;
  grid-template-columns: 150px 200px;
  grid-template-rows: repeat(3, 50px) auto repeat(5, 65px);
  align-items: center;
  margin: 0px 50px;
}

.update-user-lower-form {
  display: grid;
  grid-template-columns: 350px;
  grid-template-rows: repeat(2, 65px);
  align-items: center;
  margin: 0px 50px;
}

.update-user-jira {
  position: relative;
  margin-top: 20px;
  margin-left: 50px;
  display: grid;
  grid-template-columns: 320px 30px;
  align-items: center;
}

#nested-table {
  border-collapse: collapse;
  width: auto;
  //table-layout: fixed;
  //min-width: 500px;
}

#nested-table td {
  //border: 1px solid #ddd;
  padding: 4px;
  font-size: 14px;
}

#nested-table tr:nth-child(even) {
  background-color: rgba(250, 251, 252, 0);
}

#nested-table tr:hover {
  background-color: rgba(0, 0, 0, 0);
}

#nested-table th {
  border-bottom-width: 2px;
  padding-top: 12px;
  padding-bottom: 8px;
  padding-left: 8px;
  text-align: left;
  //background-color: #4285f4;
  color: #505052;
  font-size: 14px;
  border-bottom: none;
  padding: 8px;
  font-weight: 500;
}

.calendar-entry {
  position: relative;
  background-color: #4285f4;
  border-radius: 5px;
  height: 30px;
  margin: 5px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);

  &:hover {
    background-color: #5492f6;
  }

  &.ooo {
    background-color: #31c166;

    &:hover {
      background-color: #39d672;
    }
  }

  &.vacationPending {
    opacity: 0.25;
    background-color: #31c166;

    &:hover {
      background-color: #39d672;
    }
  }

  &.sicknessPending {
    opacity: 0.25;
    background-color: #ffdd55;

    &:hover {
      background-color: #ffe789;
    }
  }

  &.soft {
    background-image: repeating-linear-gradient(
      45deg,
      #89b1f0,
      #89b1f0 10px,
      #4285f4 10px,
      #4285f4 20px
    );

    &:hover {
      background-image: repeating-linear-gradient(
        45deg,
        #79acff,
        #79acff 10px,
        #5492f6 10px,
        #5492f6 20px
      );
    }
  }
  &.draft {
    opacity: 0.25;
    background-image: repeating-linear-gradient(
      45deg,
      #89b1f0,
      #89b1f0 10px,
      #4285f4 10px,
      #4285f4 20px
    );

    &:hover {
      background-image: repeating-linear-gradient(
        45deg,
        #79acff,
        #79acff 10px,
        #5492f6 10px,
        #5492f6 20px
      );
    }
  }
  &.hardPending {
    opacity: 0.25;
  }
  &.vacationDeletePending {
    opacity: 0.25;
    background-image: repeating-linear-gradient(
      45deg,
      #31c166,
      #31c166 10px,
      #f44242 10px,
      #f44242 20px
    );

    &:hover {
      background-image: repeating-linear-gradient(
        45deg,
        #39d672,
        #39d672 10px,
        #f77a7a 10px,
        #f77a7a 20px
      );
    }
  }
  &.sicknessDeletePending {
    opacity: 0.25;
    background-image: repeating-linear-gradient(
      45deg,
      #ffdd55,
      #ffdd55 10px,
      #f44242 10px,
      #f44242 20px
    );

    &:hover {
      background-image: repeating-linear-gradient(
        45deg,
        #ffe789,
        #ffe789 10px,
        #f77a7a 10px,
        #f77a7a 20px
      );
    }
  }
  &.projectDeletePending {
    opacity: 0.25;
    background-image: repeating-linear-gradient(
      45deg,
      #89b1f0,
      #89b1f0 10px,
      #f44242 10px,
      #f44242 20px
    );

    &:hover {
      background-image: repeating-linear-gradient(
        45deg,
        #79acff,
        #79acff 10px,
        #f77a7a 10px,
        #f77a7a 20px
      );
    }
  }
}

.avatar-icon {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: #c5c5c5;
  margin: auto 10px;
  text-align: center;
}

.avatar-icon-text {
  position: relative;
  color: #ffffff;
  font-weight: normal;
  font-size: 15px;
  top: 50%;
  transform: translate(0, -50%);
}

.tooltip-helper {
  height: 41px;
  position: absolute;
  width: calc(100% / 12 - 30px);
  z-index: 1;
}

.tooltip-list {
  font-size: 14px;
  font-weight: bold;
  margin-right: 30px;
  line-height: 20px;
}

.date-range {
  position: fixed;
  z-index: 1;
  border: 5px solid rgb(239, 242, 247);
}

.date-backdrop {
  position: fixed;
  height: 100%;
  width: 100%;
  user-select: auto;
  top: 0;
  left: 0;
  z-index: 1;
}

.booked-ratio-page-wrapper {
  padding: 0 3.125rem 6rem 3.125rem;
}

.booked-ratio-filter-bar {
  --gap: 1.25rem; // 20px
  height: 5.3125rem; // 85px;
  margin-left: var(--gap);
  padding-bottom: var(--gap);
  width: 100%;
  display: flex;
  column-gap: var(--gap);
  align-items: center;
  justify-content: flex-end;

  #calendar-filter-team,
  #calendar-filter-project,
  #calendar-filter-department {
    flex-basis: 20rem;
    z-index: var(--rp-z-index-popover);
  }
}

.roadmap-month-selection {
  margin-left: auto;
  padding: 0.625rem;
  height: 3.75rem;
  display: grid;
  grid-template-columns:
    minmax(100px, 140px) minmax(30px, 60px) minmax(30px, 60px)
    minmax(180px, auto);
  grid-template-rows: auto;
  align-items: center;
}

.roadmap-button {
  width: 100px;
}

.month-field-wrapper {
  align-items: end;
  text-align: center;
  height: 65px;
  bottom: 0;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
}

.booked-ratio-content {
  width: 100%;
  display: flex;
}

.booked-ratio-user-list {
  width: 15%;
}

.booked-ratio-overview-content {
  width: 85%;
  background-size: calc(100% / 12) 41px;
  background-image: linear-gradient(to right, #d9d9d9 1px, transparent 1px),
    linear-gradient(to bottom, #d9d9d9 1px, transparent 1px);
}

.booking-ratio-user-list-item-wrapper {
  position: relative;
  width: 100%;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: var(--rp-border-light);
  height: var(--row-height);
  line-height: 40px;
  display: grid;
  align-items: center;
  background: #f1f1f1;
  grid-template-columns: 55px auto;
  white-space: nowrap;
}

.booking-ratio-department-list-item-wrapper {
  width: 100%;
  display: flex;
  border-bottom: var(--rp-border-light);
  height: var(--row-height);
  line-height: 40px;
  align-items: center;

  &:hover {
    cursor: pointer;
  }
}

.booking-ratio-user-list-dropdown {
  margin-left: auto;
  margin-right: 10px;
}

.booking-ratio-list-content-row {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  height: 41px;
}

.booked-ratio-content-item-value {
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
}

.booked-ratio-lower-header {
  display: grid;
  grid-template-columns: 15% 85%;
}

.booked-ratio-search-bar-wrapper {
  margin: 15px;
}

.notification-popup-title {
  font-size: 1.5em;
  font-style: inherit;
  line-height: 1.16667;
  font-weight: 500;
  letter-spacing: -0.01em;
  -moz-box-flex: 1;
  margin: 24px 18px 18px 24px;
  min-height: 20px;
}

.notification-time-range-title {
  color: rgb(107, 119, 140);
  font-size: 14px;
  margin-left: 8px;
  margin-top: 24px;
}

.notifications-popup-header {
  display: grid;
  grid-template-columns: 80px 20px 140px auto;
  height: 40px;
  border-bottom: rgb(235, 236, 240) solid 4px;
  margin-left: 36px;
  margin-right: 28px;
}

.popup-background-overlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
}

.no-item-to-display {
  text-align: center;
  margin: 10px;
}

.header-notifications-popup {
  position: absolute;
  width: 550px;
  background-color: white;
  right: 20px;
  box-shadow: 0 10px 2em gray;
  border-radius: 6px;
  max-height: 100vh;
}

.notifications-header-item {
  font-size: 14px;
  margin: auto;
  width: 100%;
  height: 40px;
  align-items: center;
  display: flex;
  justify-content: center;
  font-weight: bold;
  &.selected {
    color: #4285f4;
    border-bottom: #4285f4 solid 4px;
  }
}

.popup-item-wrapper {
  border-radius: 10px;
  margin: 8px;
  margin-bottom: 15px;
  padding-left: 30px;
  padding-right: 30px;
  &:hover {
    background-color: #fafafa;
  }
  &.notification {
    display: grid;
    grid-template-columns: 90% 10%;
  }
}

.approval-item-header {
  text-align: left;
  font-size: 14px;
  padding: 15px 10px 20px 10px;
}
.approval-item-header-reason {
  font-weight: bold;
}

.notification-item-content {
  margin-top: 30px;
  margin-left: 40px;
  margin-right: 60px;
}

.notification-item-row-wrapper {
  display: grid;
  grid-template-columns: 100px auto;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
}

.notification-item-row-changes {
  display: grid;
  grid-template-columns: 80px 20px 80px;
  font-size: 12px;
}

.notification-item-row-title {
  font-size: 12px;
  font-weight: bold;
}

.notification-item-before-entry {
  opacity: 0.5;
}

.notification-data-wrapper {
  overflow-y: scroll;
  max-height: 60vh;
  cursor: auto;
  margin-left: 36px;
  margin-right: 28px;
  margin-bottom: 36px;
}

.go-to-entry {
  align-self: center;
  font-size: 14px;
  font-weight: bold;
  color: #4285f4;
}
.go-to-entry-link {
  display: flex;
  text-decoration: none;
}

.popup-approval-actions {
  display: grid;
  grid-template-columns: 125px 15px 125px auto 100px;
  margin-left: 5px;
  margin-top: 30px;
  padding-bottom: 10px;
}

.notification-mark-read {
  display: flex;
  float: right;
  padding: 15px 10px 10px 10px;
  cursor: pointer;
  position: relative;
  align-self: center;
  justify-self: center;
}

.approval-item-header-user {
  color: #4285f4;
  font-weight: bold;
}

.pulsating-circle {
  transform: translateX(-50%) translateY(-50%);
  width: 10px;
  height: 10px;
  background-color: rgb(0, 82, 204);
  border-radius: 15px;
  &:hover {
    &:before {
      content: "";
      position: relative;
      display: block;
      width: 300%;
      height: 300%;
      box-sizing: border-box;
      margin-left: -100%;
      margin-top: -100%;
      border-radius: 45px;
      background-color: #01a4e9;
      animation: pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
    }
    &:after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      display: block;
      width: 100%;
      height: 100%;
      background-color: rgb(0, 82, 204);
      border-radius: 15px;
      box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
      animation: pulse-dot 1.25s cubic-bezier(0.455, 0.03, 0.515, 0.955) -0.4s infinite;
    }
  }
}

@keyframes pulse-ring {
  0% {
    transform: scale(0.33);
  }
  80%,
  100% {
    opacity: 0;
  }
}

@keyframes pulse-dot {
  0% {
    transform: scale(0.8);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.8);
  }
}

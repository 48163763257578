.button-action {
  display: flex;
  padding: 5px;
}

.entry-accept-wrapper {
  margin-right: 10px;
}

.entry-accept {
  margin-right: auto;
  margin-left: auto;
  display: flex;
}
.entry-decline {
  margin-right: auto;
  margin-left: auto;
  display: flex;
}

.headcount-project-list-item {
  display: flex;
  width: 100%;
  align-items: center;
  white-space: nowrap;
  background: #ececec;
}

.headcount-project-list-item-wrapper {
  display: flex;
  font-size: 14px;
  padding-left: 20px;
  width: 100%;
  height: 100%;
}

.headcount-project-list-item-text {
  position: relative;
  text-overflow: ellipsis;
  overflow: hidden;
  z-index: 2;
  margin-right: 20px;

  &:hover {
    display: flex;
    overflow: visible;
    align-items: center;
    background: #ececec;
    z-index: 2;
    position: relative;
    padding-right: 20px;
  }
}

.project-list-item-link {
  color: inherit;
  text-decoration: none;
}

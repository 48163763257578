.jira-project-search {
  margin: 0px 20px 10px 20px;
  width: 300px;
}

.jira-projects {
  height: 300px;
  width: 300px;
  margin: 20px 0px;
  padding: 0px 20px;
  overflow-y: auto;
}

.jira-project {
  display: grid;
  grid-template-columns: 60px auto;
  width: 280px;
  margin: 10px 0px;
  align-items: center;
  padding: 5px;
  border-radius: 5px;
  //height: 100px;
  transition: box-shadow 0.1s, transform 0.3s;
  text-decoration: none;
  color: black;

  cursor: pointer;

  &:hover {
    box-shadow: 0px 1px 5px rgba(0, 0, 0, 0.4);
    transform: scale(1.02);
  }
}

.jira-project-thumb {
  width: 48px;
  height: 48px;
}

.jira-project-thumb-border {
  border-radius: 6px;
  width: 48px;
  height: 48px;
  overflow: hidden;
}

.user-data {
  display: grid;
  grid-template-columns: auto auto;
}

.project-dropdown {
  margin-right: 45px;
  position: absolute;
  right: 0;
}

.project-page-heading {
  display: flex;
  align-items: center;
}

.project-page-heading-jira {
  margin-left: 32px;
}

.project-page {
  margin: 25px 50px;
}

.project-page-user {
  display: grid;
  grid-template-columns: 50px 200px auto;
  grid-template-rows: 40px;
  align-items: center;
}

.project-page-entries {
  margin: 10px 50px;
}

.project-page-user-wrapper {
  margin: 0px 30px;
}

.project-page-department {
  margin-top: 20px;
  padding: 10px 20px;
  border: solid 1px #ebecf0;
  border-radius: 10px;
  // background-color: #fdfeff;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.3);
}

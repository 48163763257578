.custom-button-dropdown {
  position: relative;
  height: 40px;
  border: none;
  outline: none;
  font-size: 16px;
  font-weight: normal;
  background-color: white;
  padding: 0px 10px;
  width: 100%;
  text-align: left;

  transition: background 0.2s;

  cursor: pointer;

  &:hover {
    background: rgb(235, 235, 235);
  }

  &:focus {
    background: rgb(220, 220, 220);
  }
}

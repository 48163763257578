#projects {
  border-collapse: collapse;
  width: 100%;
  table-layout: fixed;
  min-width: 500px;
}

#projects td {
  //border: 1px solid #ddd;
  padding: 12px;
  font-size: 14px;
}

#projects tr:nth-child(even) {
  background-color: rgb(250, 251, 252);
}

#projects tr:hover {
  background-color: rgb(245, 246, 247);
}

#projects th {
  border-bottom-width: 2px;
  padding-top: 12px;
  padding-bottom: 8px;
  padding-left: 8px;
  text-align: left;
  //background-color: #4285f4;
  color: rgb(94, 108, 132);
  font-size: 14px;
  border-bottom: 2px solid #ddd;
  padding: 8px;
  font-weight: 500;
}

.clickable-tr {
  cursor: pointer;
}

.projects-list-skeleton {
}

.projects-list-skeleton-element {
  margin: 10px;
  height: 32px;
  background-color: var(--rp-grey);
  animation: skeleton-fading 2s linear infinite;
}

@keyframes skeleton-fading {
  0% {
    opacity: 0.1;
  }
  40% {
    opacity: 0.4;
  }
  100% {
    opacity: 0.1;
  }
}

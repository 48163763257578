.custom-button {
  position: relative;
  padding: 10px;
  min-width: 8rem;
  height: 40px;
  background: #4285f4;
  border: none;
  border-radius: 5px;
  outline: none;
  color: white;
  font-size: 16px;
  font-weight: normal;

  cursor: pointer;

  &:hover {
    background: #5492f6;
  }

  &:active {
    background: #4285f4;
  }

  &:disabled {
    opacity: 0.3;

    &:hover {
      background: #4285f4;
    }
    cursor: auto;
  }

  &.bold {
    font-weight: bold;
  }
  &.font14 {
    font-size: 14px;
  }
}

.custom-button-red {
  display: flex;
  position: relative;
  padding: 10px;
  min-width: 50px;
  height: 40px;
  background: #d32f2f;
  border: none;
  border-radius: 5px;
  outline: none;
  color: white;
  font-size: 16px;
  font-weight: normal;

  cursor: pointer;

  &:hover {
    background: #d34343;
  }

  &:active {
    background: #d32f2f;
  }

  &:disabled {
    opacity: 0.3;

    &:hover {
      background: #d32f2f;
    }
    cursor: auto;
  }
  &.bold {
    font-weight: bold;
  }
  &.font14 {
    font-size: 14px;
  }
}

.custom-button-green {
  display: flex;
  position: relative;
  padding: 10px;
  min-width: 50px;
  height: 40px;
  background: #00cc83;
  border: none;
  border-radius: 5px;
  outline: none;
  color: white;
  font-size: 16px;
  font-weight: normal;

  cursor: pointer;

  &:hover {
    background: #1aca8c;
  }

  &:active {
    background: #00cc83;
  }

  &:disabled {
    opacity: 0.3;

    &:hover {
      background: #00cc83;
    }
    cursor: auto;
  }
  &.font14 {
    font-size: 14px;
  }
  &.bold {
    font-weight: bold;
  }
}

.custom-button-gray {
  display: flex;
  position: relative;
  padding: 10px;
  min-width: 50px;
  height: 40px;
  background: #b0aeae;
  border: none;
  border-radius: 5px;
  outline: none;
  color: rgb(56, 56, 56);
  font-size: 16px;
  font-weight: normal;

  cursor: pointer;

  &:hover {
    background: #c7c5c5;
  }

  &:active {
    background: #b0aeae;
  }

  &:disabled {
    opacity: 0.3;

    &:hover {
      background: #b0aeae;
    }
    cursor: auto;
  }
  &.bold {
    font-weight: bold;
  }
  &.font14 {
    font-size: 14px;
  }
}

.sidebar {
  position: fixed;
  left: 0;
  top: var(--rp-header-height);
  height: 100%;
  width: 320px;
  border-width: 1px;
  border-top: none;
  border-left: none;
  border-bottom: none;
  border-right: var(--rp-border-medium);
  background-color: white;
  box-shadow: 4px 0 20px rgba(80, 80, 80, 0.4);
  overflow-y: auto;

  z-index: 13;

  &.right {
    right: 0;
    left: auto;
  }
}

.calendar-header {
  position: fixed;
  display:flex;
  top: 60px;
  left: 0;
  width: 100%;
  box-shadow: 4px 0 20px rgba(80, 80, 80, 0.8);
  border-bottom: 1px solid #d9d9d9;
  border-top: none;
  border-left: none;
  border-right: none;
  background-color: #ffffff;
  z-index: 1;
}

.calendar-table-content {
  position: relative;
}
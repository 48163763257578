.user-list-entry {
  cursor: pointer;
  margin: 5px 0px;
}

.user-list-mail {
  margin: 5px 0px;
  font-size: 12px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 300px;
}

.user-list-user {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: grid;
  grid-template-columns: 50px auto;
  min-height: 41px;
}

@media (max-width: 1400px) {
  .user-list-mail {
    display: none;
  }
}

.update-project-form {
    display: grid;
    grid-template-columns: 150px 200px;
    grid-template-rows: 50px 50px 50px 50px 50px;
    align-items: center;
    margin: 0px 50px;
  }

.calendar-range {
  position: fixed;
  z-index: 1;
  right: 840px;
}